import styled from "./ThemeProvider";

const MainTitle = styled.h2`
  border-left: 3px solid ${(props) => props.theme.accentColor};
  color: ${(props) => props.theme.textColorLight};
  padding: 0 16px;
  margin-bottom: 10%;
`;

export default MainTitle;
