import { css } from "@emotion/core";

import { ITheme } from "./colors";

const prismStyle = (theme: ITheme) => css`
  :not(pre) > code[class*="language-"],
  pre[class*="language-"] {
    background: ${theme.code.background};
  }

  code[class*="language-"],
  pre[class*="language-"] {
    font-family: monospace;
    text-shadow: 0 1px
      ${theme.name === "light" ? "white" : "rgba(255, 255, 255, 0.1)"};
    color: ${theme.name === "light" ? "black" : "white"};
  }

  pre[class*="language-"] {
    font-size: 10pt;
    margin: 32px 0;
    padding: 16px;
    line-height: 1.5;
    text-shadow: none;
  }

  .token.operator {
    background: inherit;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: ${theme.code.comment};
  }

  .token.atrule,
  .token.attr-value,
  .token.keyword {
    color: ${theme.code.keyword};
  }

  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted {
    color: ${theme.code.selector};
  }

  .token.function,
  .token.class-name {
    color: ${theme.code.function};
  }

  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string {
    color: ${theme.code.operator};
  }

  .token.property,
  .token.tag,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: ${theme.code.property};
  }

  .token.punctuation {
    color: ${theme.code.punctuation};
  }
`;

export default prismStyle;
