import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Global } from "@emotion/core";

import Header from "./Header";
import Footer from "./Footer";

import styled from "./styled/ThemeProvider";
import initialStyle from "./styled/initialStyle";

const Main = styled.main`
  padding: 8px;
  border-top: 8px solid ${(props) => props.theme.accentColor};
  /*
    REMEMBER TO UPDATE!!
    (56 is the footer * 2) + 16px is padding + 8 border top - 4px for some reason?
  */
  min-height: calc(100% - ((56px * 2) + 16px + 8px - 4px));

  @media (min-width: 769px) {
    display: grid;
    grid-template-columns: 0.75fr 1fr 0.75fr;
    grid-template-rows: 50px 1fr;
    padding-top: 60px;

    *:first-of-type {
      grid-column-start: 2;
    }
  }
`;

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(data) => (
          <>
            <Global styles={initialStyle} />
            <Main>
              <Header title={data.site.siteMetadata.title} />
              {children}
            </Main>
            <Footer />
          </>
        )}
      />
    );
  }
}

export default Layout;
