import React from "react";
import { Link } from "gatsby";

import { IOtherArticle } from "../templates/BlogPost";
import ChevronLeft from "../../content/assets/chevron-left-regular.svg";
import ChevronRight from "../../content/assets/chevron-right-regular.svg";

interface IProps {
  previous?: IOtherArticle;
  next?: IOtherArticle;
}

const PreviousNextArticle: React.FC<IProps> = ({ previous, next }) => (
  <ul
    style={{
      display: `flex`,
      flexWrap: `wrap`,
      justifyContent: `space-between`,
      listStyle: `none`,
      padding: 0,
    }}
  >
    <li style={{ flex: 1 }}>
      {previous && (
        <Link to={previous.fields.slug} rel="prev">
          <ChevronLeft width="6px" style={{ marginRight: "8px" }} />
          {previous.frontmatter.title}
        </Link>
      )}
    </li>
    <li style={{ flex: 1 }}>
      {next && (
        <Link to={next.fields.slug} rel="next">
          {next.frontmatter.title}
          <ChevronRight width="6px" style={{ marginLeft: "8px" }} />
        </Link>
      )}
    </li>
  </ul>
);

export default PreviousNextArticle;
