import React from "react";
import Helmet from "react-helmet";

interface IProps {
  author: string;
  canonicalUrl: string;
  datePublished: string | false;
  description: string;
  image: string;
  defaultImage: string;
  title: string;
  url: string;
}

const SchemaOrg: React.FC<IProps> = ({
  author,
  canonicalUrl,
  datePublished,
  description,
  image,
  defaultImage,
  title,
  url,
}) => {
  const schema = [
    {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": url,
            name: title,
            image,
          },
        },
      ],
    },
    {
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      url,
      name: title,
      headline: title,
      image: {
        "@type": "ImageObject",
        url: image,
      },
      description,
      author: {
        "@type": "Person",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        url: canonicalUrl,
        logo: defaultImage,
        name: author,
      },
      mainEntityOfPage: {
        "@type": "WebSite",
        "@id": canonicalUrl,
      },
      datePublished,
    },
  ];

  return (
    <Helmet>
      {/* Schema.org tags */}
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default React.memo(SchemaOrg);
