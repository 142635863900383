import React from "react";
import { css } from "@emotion/core";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
} from "react-share";

import FacebookIcon from "../../content/assets/facebook-f-brands.svg";
import TwitterIcon from "../../content/assets/twitter-brands.svg";
import LinkedinIcon from "../../content/assets/linkedin-in-brands.svg";
import RedditIcon from "../../content/assets/reddit-alien-brands.svg";
import WhatsAppIcon from "../../content/assets/whatsapp-brands.svg";

import { Button } from "./styled";

const spanStyle = css`
  vertical-align: text-bottom;
  margin-left: 8px;
`;

const socialColors = {
  facebook: "#3b5998",
  twitter: "#1da1f2",
  linkedin: "#0077b5",
  reddit: "#ff4500",
  whatsapp: "#128c7e",
};

interface IProps {
  socialConfig: {
    twitterHandle: string;
    config: {
      url: string;
      title: string;
    };
  };
  tags: string[];
}

const Share: React.FC<IProps> = ({ socialConfig, tags }) => (
  <div>
    <Button color={socialColors.facebook}>
      <FacebookShareButton url={socialConfig.config.url}>
        <FacebookIcon width={14} height={14} />
        <span css={spanStyle}>Facebook</span>
      </FacebookShareButton>
    </Button>
    <Button color={socialColors.twitter}>
      <TwitterShareButton
        url={socialConfig.config.url}
        title={socialConfig.config.title}
        via={socialConfig.twitterHandle.split("@").join("")}
        hashtags={tags}
      >
        <TwitterIcon width={14} height={14} />
        <span css={spanStyle}>Twitter</span>
      </TwitterShareButton>
    </Button>
    <Button color={socialColors.linkedin}>
      <LinkedinShareButton url={socialConfig.config.url}>
        <LinkedinIcon width={14} height={14} />
        <span css={spanStyle}>LinkedIn</span>
      </LinkedinShareButton>
    </Button>
    <Button color={socialColors.reddit}>
      <RedditShareButton
        url={socialConfig.config.url}
        title={socialConfig.config.title}
      >
        <RedditIcon width={14} height={14} />
        <span css={spanStyle}>Reddit</span>
      </RedditShareButton>
    </Button>
    <Button color={socialColors.whatsapp}>
      <WhatsappShareButton
        url={socialConfig.config.url}
        title={socialConfig.config.title}
      >
        <WhatsAppIcon width={14} height={14} />
        <span css={spanStyle}>WhatsApp</span>
      </WhatsappShareButton>
    </Button>
  </div>
);

export default Share;
