import styled from "./ThemeProvider";

const ArticlePage = styled.article`
  margin-bottom: 56px;

  .article-date {
    color: ${(props) => props.theme.textColorLight};
    display: block;
    margin: 32px 0;
    font-weight: 600;
  }

  .title {
    margin-bottom: 0;
  }

  .sub-title {
    color: ${(props) => props.theme.textColorLight};
    margin: 0;
  }

  figure {
    margin: 16px 0 32px;

    figcaption {
      font-style: italic;
      font-size: 10pt;
      color: ${(props) => props.theme.textColorLight};
      padding: 8px;
      text-align: center;
    }
  }

  .article {
    h2,
    h3,
    h4 {
      font-weight: 600;
    }

    h2 {
      font-size: 20pt;
    }

    h3 {
      font-size: 16pt;
    }

    h4 {
      font-size: 14pt;
    }

    blockquote {
      border-left: 3px solid ${(props) => props.theme.accentColor};
      color: ${(props) => props.theme.textColorLight};
      margin: 16px;
      padding: 0 8px;
      font-size: 14pt;
    }

    a.gatsby-resp-image-link {
      background: none;
      text-decoration: none;
      padding: 32px 0;

      .gatsby-resp-image-image {
        box-shadow: inset 0px 0px 0px 400px
          ${(props) => props.theme.backgroundColor} !important;
      }
    }
    ol,
    ul {
      list-style: none;
      padding: 0;

      li {
        position: relative;
        padding-left: 26px;
        margin: 16px 0;

        &:before {
          content: "";
          height: 4px;
          width: 14px;
          position: absolute;
          top: calc(50% - 2px);
          left: 0;
          background-color: ${(props) => props.theme.accentColor};
        }
      }
    }
  }
`;

export default ArticlePage;
