import React, { ButtonHTMLAttributes } from "react";
import { css } from "@emotion/core";

import styled from "./ThemeProvider";

interface IStyledButtonProps {
  text?: string;
  color?: string;
}

const StyledButton = styled.div<IStyledButtonProps>`
  position: relative;
  display: inline-block;
  margin: 8px 12px 8px 0;

  &:before {
    content: "";
    position: absolute;
    top: calc(100% - 2px);
    right: -2px;
    bottom: -2px;
    left: 4px;
    background: ${(props) => props.color || props.theme.accentColor};
    z-index: -1;
  }

  &:after {
    content: "";
    position: absolute;
    top: 4px;
    right: -2px;
    bottom: 2px;
    left: calc(100% - 2px);
    background: ${(props) => props.color || props.theme.accentColor};
    z-index: -1;
  }

  button {
    cursor: pointer;
    background: transparent;
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.backgroundColor};
    font-size: 10.5pt;
    font-weight: 600;
    border: 1px solid ${(props) => props.theme.backgroundColorDark};
    border-radius: 2px;

    svg {
      color: ${(props) => props.theme.backgroundColor};
      vertical-align: middle;
    }

    &:before {
      background: ${(props) => props.color || props.theme.accentColor};
    }

    &:hover {
      color: ${(props) => props.theme.textColor};

      &:before {
        background: inherit;
      }

      svg {
        color: ${(props) => props.color || props.theme.backgroundColorDark};
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 4px;
      right: 2px;
      bottom: 2px;
      left: 4px;
      z-index: -1;
    }

    &:disabled {
      border: 1px solid ${(props) => props.theme.textColorLight};
      color: ${(props) => props.theme.textColorLight};
    }

    ${(props) =>
      props.text &&
      css`
        span {
          padding: 6px 16px 8px 12px;
          display: inline-block;
          color: ${props.theme.textColor};
        }
      `}

    > span > * {
      padding: 6px 16px 8px 12px;
      display: inline-block;
    }
  }

  a {
    border: 0;
    box-shadow: none;
    background: none;
    text-decoration: none;

    &:hover {
      background: none;
    }
  }
`;

interface IProps extends React.HTMLProps<HTMLButtonElement> {
  color?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  text?: string;
}

const Button: React.FC<IProps> = ({
  children,
  color = "",
  type = "button",
  text = "",
  ...props
}) => (
  <StyledButton color={color} text={text}>
    <button type={type} {...props}>
      <span>{text || children}</span>
    </button>
  </StyledButton>
);

export default Button;
