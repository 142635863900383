/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { css } from "@emotion/core";

const styledBio = () => css`
  font-family: "Courier New", Courier, monospace;
  text-align: right;
`;

const bioQuery = graphql`
  query BioQuery {
    site {
      siteMetadata {
        author
      }
    }
  }
`;

const Bio = () => (
  <StaticQuery
    query={bioQuery}
    render={(data) => {
      const { author } = data.site.siteMetadata;
      return (
        <h4 css={styledBio}>
          by <Link to="/about">{author}</Link>
        </h4>
      );
    }}
  />
);

export default Bio;
