import { css } from "@emotion/core";
import emotionNormalize from "emotion-normalize";

import { ITheme } from "./colors";
import prismStyle from "./prismStyle";

const initialStyle = (theme: ITheme) => css`
  ${emotionNormalize}
  html,
  body,
  #___gatsby,
  #___gatsby > div {
    margin: 0;
    height: 100%;
  }

  body {
    background-color: ${theme.backgroundColor};
    color: ${theme.textColor};
    font-family: "Open Sans", sans-serif;
    line-height: 1.6;
  }

  a {
    color: ${theme.textColor};
    text-decoration: none;
  }

  a:not(.unstyled) {
    text-decoration: underline;
    padding: 0 4px;
    background: linear-gradient(
        to right,
        ${theme.backgroundColor} 8px,
        transparent 8px,
        transparent
      ),
      linear-gradient(
        to bottom,
        transparent 1.2em,
        ${theme.accentColor} 1.2em,
        ${theme.accentColor}
      );

    :hover {
      background: ${theme.accentColor};
    }
  }

  h1 {
    font-weight: 500;
  }

  h2,
  h3,
  h4 {
    font-weight: 300;
  }

  h4 > a:not(.unstyled) {
    background: linear-gradient(
        to right,
        ${theme.backgroundColor} 8px,
        transparent 8px,
        transparent
      ),
      linear-gradient(
        to bottom,
        ${theme.backgroundColor} 0.95em,
        ${theme.accentColor} 0.95em,
        ${theme.accentColor}
      );
  }

  h1 {
    display: block;
    font-size: 30pt;
  }
  h2 {
    display: block;
    font-size: 22pt;
  }
  h3 {
    display: block;
    font-size: 16pt;
  }
  h4,
  p {
    display: block;
    font-size: 12pt;
  }
  h5 {
    display: block;
    font-size: 10.5pt;
  }
  h6 {
    display: block;
    font-size: 8pt;
  }

  hr {
    margin: 40px 0;
  }

  img.gatsby-resp-image-image {
    object-fit: contain;
  }

  /* prism code style */
  ${prismStyle(theme)}

  .none {
    display: none;
  }
`;

export default initialStyle;
