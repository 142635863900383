import React from "react";
import { StaticQuery, graphql } from "gatsby";

import styled from "./styled/ThemeProvider";
import GithubIcon from "../../content/assets/github-brands.svg";
import LinkedinIcon from "../../content/assets/linkedin-in-brands.svg";
import TwitterIcon from "../../content/assets/twitter-brands.svg";

const StyledFooter = styled.footer`
  padding: 16px 0 8px;
  display: flex;
  justify-content: space-around;
  background-color: ${(props) => props.theme.backgroundColorDark};
  height: 32px;

  svg.svg-inline--fa {
    width: 22px;
    height: 22px;
  }

  svg path {
    fill: ${(props) => props.theme.accentColor};
  }
`;

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            social {
              github
              linkedin
              twitter
            }
          }
        }
      }
    `}
    render={(data) => (
      <StyledFooter>
        <a
          className="unstyled"
          aria-label="Linkedin"
          href={data.site.siteMetadata.social.linkedin}
        >
          <LinkedinIcon width={25} height={25} />
        </a>
        <a
          className="unstyled"
          aria-label="Github"
          href={data.site.siteMetadata.social.github}
        >
          <GithubIcon width={25} height={25} />
        </a>
        <a
          className="unstyled"
          aria-label="Twitter"
          href={data.site.siteMetadata.social.twitter}
        >
          <TwitterIcon width={25} height={25} />
        </a>
      </StyledFooter>
    )}
  />
);

export default Footer;
