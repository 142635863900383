import React from "react";
import { Link } from "gatsby";

import Button from "./Button";

interface IProps {
  to: string;
}

const TagButton: React.FC<IProps> = ({ children, to }) => (
  <Button>
    <Link to={to}>{children}</Link>
  </Button>
);

export default TagButton;
