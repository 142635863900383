import React from "react";

import Gitalk from "gitalk";
import "gitalk/dist/gitalk.css";

import styled from "./styled/ThemeProvider";

const runGitalk = (location: Location) => {
  const gitalk = new Gitalk({
    clientID: "6d214c2b4bd97e2e9738",
    clientSecret: "3c5ca75f527d775cc497ec8eb8331a5132d78765",
    repo: "blog-comments",
    owner: "Kornil",
    admin: ["Kornil"],
    id: location.pathname,
  });

  gitalk.render("gitalk-container");
};

const StyledComments = styled.div`
  .gt-container {
    /* "be the first guy to leave a comment" */
    .gt-comments-null {
      display: none;
    }

    .gt-avatar-github {
      background: none;
    }

    /* Links */
    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        color: inherit;
      }
    }

    .gt-link {
      background: none;
      text-decoration: none;
    }

    .gt-ico-text {
      text-decoration: underline;
    }

    .gt-link {
      border: inherit;
    }

    .gt-comment-text,
    .gt-comment-date {
      color: ${(props) => props.theme.textColorLight};
    }

    a.gt-comment-like,
    a.gt-comment-reply,
    .gt-comment-edit {
      text-decoration: none;
      background: none;

      .gt-svg svg {
        fill: ${(props) => props.theme.textColor};

        &:hover {
          fill: ${(props) => props.theme.accentColor};
        }
      }
    }

    /* Info Icon */
    .gt-header-controls-tip {
      padding: 0;

      .gt-svg {
        display: none;
      }
    }

    /* Dropdown */

    .gt-user .is--poping .gt-ico svg {
      fill: inherit;
    }

    .gt-popup {
      padding: 0;
      border-radius: 2px;

      .gt-action {
        background: none;
      }

      .gt-action.is--active:before {
        background: ${(props) => props.theme.accentColor};
      }

      .gt-link-project {
        border: none;
      }

      .gt-version {
        color: ${(props) => props.theme.textColorLight};
      }
    }

    /* Textarea */

    .gt-header-textarea {
      border-radius: 2px;
      border-color: ${(props) => props.theme.backgroundColorDark};

      &:hover,
      &:focus {
        background: ${(props) => props.theme.backgroundColor};
        box-shadow: 4px 4px 0 ${(props) => props.theme.accentColor};
      }

      &:focus {
        outline: none;
        border-color: ${(props) => props.theme.textColor};
      }
    }

    /* Button */
    .gt-header-controls .gt-btn {
      position: relative;
      cursor: pointer;
      background: transparent;
      margin: 8px 12px 8px 0;
      padding: 0;
      color: ${(props) => props.theme.textColor};
      border: 1px solid ${(props) => props.theme.backgroundColorDark};
      border-radius: 2px;

      &:before {
        content: "";
        position: absolute;
        top: 2.5px;
        right: 0px;
        bottom: 0px;
        left: 2.5px;
        z-index: -1;
        background: ${(props) => props.theme.accentColor};
      }

      &:hover {
        &:before {
          background: none;
        }
      }

      .gt-btn-text {
        font-size: 10.5pt;
        font-weight: 600;
        display: inline-block;
        padding: 6px 16px 8px 12px;

        &:before {
          content: "";
          position: absolute;
          top: calc(100% - 1px);
          right: -2px;
          bottom: -3px;
          left: 2.5px;
          background: ${(props) => props.color || props.theme.accentColor};
          z-index: -1;
        }

        &:after {
          content: "";
          position: absolute;
          top: 2.5px;
          right: -3px;
          bottom: -3px;
          left: calc(100% - 1px);
          background: ${(props) => props.theme.accentColor};
          z-index: -1;
        }
      }
    }

    /* Admin comment background */
    .gt-comment-admin .gt-comment-content {
      background: #f5f2f0;
    }
  }
`;

interface IProps {
  location: Location;
}

const CommentsBlock: React.FC<IProps> = ({ location }) => {
  React.useEffect(() => {
    runGitalk(location);
  }, [location]);
  return <StyledComments id="gitalk-container" />;
};

export default CommentsBlock;
