import styled from "./ThemeProvider";

const FormInput = styled.div`
  margin: 16px 0;

  input,
  textarea {
    border: 1px solid ${(props) => props.theme.textColorLight};
    border-radius: 2px;
    background: ${(props) => props.theme.backgroundColor};

    /* account for padding on both sides */
    width: calc(100% - 16px);
    padding: 8px;

    &:hover,
    &:focus {
      background: ${(props) => props.theme.backgroundColor};
      box-shadow: 4px 4px 0 ${(props) => props.theme.accentColor};
      outline: none;
    }

    &:focus {
      outline: none;
      border-color: ${(props) => props.theme.textColor};
    }
  }

  label {
    font-weight: 600;
    font-size: 10.5pt;
    display: block;
    margin-bottom: 8px;
  }
`;

export default FormInput;
