/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import SchemaOrg from "./SchemaOrg";
import favicon196 from "../../static/favicon-196x196.png";

interface IProps {
  description?: string;
  keywords?: string[];
  title: string;
  imageURL?: string;
  location?: Location;
  datePublished?: string;
}

const Seo: React.FC<IProps> = ({
  description,
  datePublished,
  keywords,
  title,
  imageURL,
  location,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const image = imageURL
    ? `${site.siteMetadata.siteUrl}${imageURL}`
    : `${site.siteMetadata.siteUrl}${favicon196}`;
  const url = location ? location.href : site.siteMetadata.siteUrl;
  const seoTitle = title
    ? `De Code Natura | ${title}`
    : site.siteMetadata.title;

  return (
    <>
      <Helmet>
        <html lang="en" />
        {/* General tags */}
        <title>{seoTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="image" content={image} />
        {keywords && <meta name="keywords" content={keywords.join(",")} />}
        <link rel="canonical" href={url} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        {location ? <meta property="og:type" content="article" /> : null}
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={image} />
        {/* <meta property="fb:app_id" content={seo.social.fbAppID} /> */}

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@fragno92" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <SchemaOrg
        url={url}
        title={seoTitle}
        image={image}
        defaultImage={`https://${site.siteMetadata.siteUrl}${favicon196}`}
        description={metaDescription}
        datePublished={datePublished || false}
        canonicalUrl={site.siteMetadata.siteUrl}
        author={site.siteMetadata.author}
      />
    </>
  );
};

export default Seo;
