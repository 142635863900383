import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/core";
import { withTheme } from "emotion-theming";

import { ITheme } from "./styled/colors";

import RSSIcon from "../../content/assets/rss-solid.svg";
import SunIcon from "../../content/assets/sun-solid.svg";
import MoonIcon from "../../content/assets/moon-solid.svg";

const styledHeader = (theme: ITheme) => css`
  display: flex;
  justify-content: space-between;

  h4 {
    margin: 0;
    font-family: "Courier New", Courier, monospace;
  }

  .icon {
    background: none;
    color: ${theme.textColor};
    cursor: pointer;

    &:first-of-type {
      margin-right: 8px;
    }

    &:hover {
      background: none;
      color: ${theme.accentColor};
    }
  }
`;

interface IProps {
  title: string;
  theme: ITheme;
}

const Header: React.FC<IProps> = ({ title, theme }) => {
  const onThemeSwitch = () => {
    typeof window !== "undefined" &&
      window.__setPreferredTheme(theme.name === "light" ? "dark" : "light");
  };

  return (
    <nav css={styledHeader}>
      <h4>
        <Link to="/">{title}</Link>
      </h4>
      <div>
        <a className="icon" onClick={onThemeSwitch}>
          {theme.name === "dark" ? (
            <MoonIcon width={18} height={18} />
          ) : (
            <SunIcon width={18} height={18} />
          )}
        </a>
        <a className="icon" href="/rss.xml">
          <RSSIcon width={18} height={18} />
        </a>
      </div>
    </nav>
  );
};

export default withTheme(Header);
