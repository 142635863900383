import styled from "./ThemeProvider";

const ArticleCard = styled.article`
  margin: 48px 0;

  h3 {
    font-size: 20pt;
    margin: 8px 0;
  }
`;

export default ArticleCard;
